// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../shared/logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZQyt2zfKgMQg1JZLWXmW {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center 45px;
  padding-top: 145px;
}

.M0H1Cy23cXWnDZuysBR9 {
  width: 320px;
  margin: 0 auto 30px;
  padding: 10px 40px 27px;
  background-color: #f7f6fb;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.09);
  font-size: 16px;
  line-height: 22px;
}

.tYjapsY_pKRHCgPPCwMQ {
  font-size: 16px;
  color: #5d5d5d;
}

.M0H1Cy23cXWnDZuysBR9 .UrYiAc1NThTG1GN8Poxf {
  display: inline-block;
  margin-left: 13px;
  font-size: 13px;
  color: #5d5d5d;
}

.c1TNiCeODho0n1kc7_2v {
  font-size: 14px;
  margin-top: 30px;
  color: #5d5d5d;
}
`, "",{"version":3,"sources":["webpack://./Login/App.css"],"names":[],"mappings":"AAAA;EACE,yDAA2C;EAC3C,4BAA4B;EAC5B,gCAAgC;EAChC,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,+CAA+C;EAC/C,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".wrapper {\n  background-image: url(\"../shared/logo.png\");\n  background-repeat: no-repeat;\n  background-position: center 45px;\n  padding-top: 145px;\n}\n\n.form {\n  width: 320px;\n  margin: 0 auto 30px;\n  padding: 10px 40px 27px;\n  background-color: #f7f6fb;\n  border: 1px solid #c4c4c4;\n  border-radius: 10px;\n  box-shadow: 1px 1px 8px 2px rgba(0, 0, 0, 0.09);\n  font-size: 16px;\n  line-height: 22px;\n}\n\n.label {\n  font-size: 16px;\n  color: #5d5d5d;\n}\n\n.form .checkboxLabel {\n  display: inline-block;\n  margin-left: 13px;\n  font-size: 13px;\n  color: #5d5d5d;\n}\n\n.helpBlock {\n  font-size: 14px;\n  margin-top: 30px;\n  color: #5d5d5d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ZQyt2zfKgMQg1JZLWXmW`,
	"form": `M0H1Cy23cXWnDZuysBR9`,
	"label": `tYjapsY_pKRHCgPPCwMQ`,
	"checkboxLabel": `UrYiAc1NThTG1GN8Poxf`,
	"helpBlock": `c1TNiCeODho0n1kc7_2v`
};
export default ___CSS_LOADER_EXPORT___;
