// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._iTA2e6sQ0OHXV7EwJbz {
  box-sizing: border-box;
  border: 1px solid #ccc;
  padding: 5px 10px 0 10px;
  overflow-y: auto;
  background-color: white;
}

.ZZg3w3FbVP2NVF_6nexg {
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
}

.ZZg3w3FbVP2NVF_6nexg > label {
  margin-bottom: 0;
}

.puTHHr7cA2b0hMjYNiCU,
.mKF8VbXg4tbReb5vAXaV {
  font-weight: bold;
  color: #888;
  margin-top: 10px;
  margin-bottom: 5px;
}

.mKF8VbXg4tbReb5vAXaV {
  margin-left: 20px;
}

.puTHHr7cA2b0hMjYNiCU:first-child {
  margin-top: 0;
}

.I8M8Uq1a1y9TcN9bFyzg {
  margin-left: 20px;
}

.Bg2gSJLLCYNiwE2_vrX4 {
  margin-left: 40px;
}

/* label needed here to override bootstrap */
label.MhiLis0hVfXXn29javai {
  display: block;
  font-weight: normal;
  cursor: pointer;
}

/* input[type="checkbox"] needed here to override bootstrap */
input[type="checkbox"].vGJJUM5pLuF9tjcssAPw {
  float: left;
  margin-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./shared/input-components/CheckboxList.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,wBAAwB;EACxB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,6BAA6B;EAC7B,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA,4CAA4C;AAC5C;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA,6DAA6D;AAC7D;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".checkboxList {\n  box-sizing: border-box;\n  border: 1px solid #ccc;\n  padding: 5px 10px 0 10px;\n  overflow-y: auto;\n  background-color: white;\n}\n\n.checkboxListDivider {\n  padding-bottom: 5px;\n  border-bottom: 1px solid #eee;\n  margin-bottom: 5px;\n}\n\n.checkboxListDivider > label {\n  margin-bottom: 0;\n}\n\n.groupHeading,\n.subGroupHeading {\n  font-weight: bold;\n  color: #888;\n  margin-top: 10px;\n  margin-bottom: 5px;\n}\n\n.subGroupHeading {\n  margin-left: 20px;\n}\n\n.groupHeading:first-child {\n  margin-top: 0;\n}\n\n.indentLeft {\n  margin-left: 20px;\n}\n\n.doubleIndentLeft {\n  margin-left: 40px;\n}\n\n/* label needed here to override bootstrap */\nlabel.checkboxLabel {\n  display: block;\n  font-weight: normal;\n  cursor: pointer;\n}\n\n/* input[type=\"checkbox\"] needed here to override bootstrap */\ninput[type=\"checkbox\"].checkbox {\n  float: left;\n  margin-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxList": `_iTA2e6sQ0OHXV7EwJbz`,
	"checkboxListDivider": `ZZg3w3FbVP2NVF_6nexg`,
	"groupHeading": `puTHHr7cA2b0hMjYNiCU`,
	"subGroupHeading": `mKF8VbXg4tbReb5vAXaV`,
	"indentLeft": `I8M8Uq1a1y9TcN9bFyzg`,
	"doubleIndentLeft": `Bg2gSJLLCYNiwE2_vrX4`,
	"checkboxLabel": `MhiLis0hVfXXn29javai`,
	"checkbox": `vGJJUM5pLuF9tjcssAPw`
};
export default ___CSS_LOADER_EXPORT___;
