// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F0P0Ok_DX7pzMz6iTxtw {
  padding: 8px 35px 8px 14px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.IeDKlAiS3Sr34Dbfz0_G {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}

.jJdR5Nxpx8thNut6jni2 {
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  color: #b56400;
}

.qdUMrdgsAOq333hFeaS9 {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #3a87ad;
}

.XGhBj9hgA_glBq1W8Fxr {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}
`, "",{"version":3,"sources":["webpack://./shared/Alert.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,6CAA6C;EAC7C,yBAAyB;EACzB,yBAAyB;EACzB,0BAA0B;EAC1B,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EAEE,yBAAyB;EACzB,qBAAqB;EACrB,cAAc;AAChB","sourcesContent":[".alert {\n  padding: 8px 35px 8px 14px;\n  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  -webkit-border-radius: 4px;\n  -moz-border-radius: 4px;\n  border-radius: 4px;\n}\n\n.error {\n  composes: alert;\n  background-color: #f2dede;\n  border-color: #eed3d7;\n  color: #b94a48;\n}\n\n.warning {\n  composes: alert;\n  background-color: #fcf8e3;\n  border: 1px solid #fbeed5;\n  color: #b56400;\n}\n\n.info {\n  composes: alert;\n  background-color: #d9edf7;\n  border-color: #bce8f1;\n  color: #3a87ad;\n}\n\n.success {\n  composes: alert;\n  background-color: #dff0d8;\n  border-color: #d6e9c6;\n  color: #468847;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": `F0P0Ok_DX7pzMz6iTxtw`,
	"error": `IeDKlAiS3Sr34Dbfz0_G F0P0Ok_DX7pzMz6iTxtw`,
	"warning": `jJdR5Nxpx8thNut6jni2 F0P0Ok_DX7pzMz6iTxtw`,
	"info": `qdUMrdgsAOq333hFeaS9 F0P0Ok_DX7pzMz6iTxtw`,
	"success": `XGhBj9hgA_glBq1W8Fxr F0P0Ok_DX7pzMz6iTxtw`
};
export default ___CSS_LOADER_EXPORT___;
