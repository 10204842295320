// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EoZJ7cmvuyG9sJ6kMLFt {
  padding: 25px 40px;
}

.eDApc_KxdmRV8Hnbk0FR {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
}

.phC4uJq5FDhSIwIA7TAu textarea {
  resize: vertical;
}

.mEBzzTgG72BlCOC3BoCG {
  display: flex;
  align-items: center;
}

.phC4uJq5FDhSIwIA7TAu input[type="file"] {
  display: none;
}

.liLd6CGon7UCzzUmqHA_,
.rACCteIfS_NPdkGNt94Y {
  margin-right: 15px;
  font-weight: 400;
}

.pAUA5hzv5woqDFAo9vHM {
  margin: 10px 0 0;
  font-size: 12px;
}

.ivRByHZLIrQXSRwe5sVd {
  padding: 5px 10px;
}

.ADZVLktnXCVjR3R322f7 {
  margin-bottom: 20px;
}

.Wym7mvh7OClMmEAAf49d {
  margin-right: 3px;
}

.IxwNDCQQJNUbzeUbLgem {
  font-size: 18px;
}

.IxwNDCQQJNUbzeUbLgem span {
  color: #4580ad;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./shared/AppFrame/HelpModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  padding: 25px 40px;\n}\n\n.label {\n  margin-top: 20px;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.email textarea {\n  resize: vertical;\n}\n\n.formButtons {\n  display: flex;\n  align-items: center;\n}\n\n.email input[type=\"file\"] {\n  display: none;\n}\n\n.file,\n.submit {\n  margin-right: 15px;\n  font-weight: 400;\n}\n\n.small {\n  margin: 10px 0 0;\n  font-size: 12px;\n}\n\n.success {\n  padding: 5px 10px;\n}\n\n.help {\n  margin-bottom: 20px;\n}\n\n.icon {\n  margin-right: 3px;\n}\n\n.call {\n  font-size: 18px;\n}\n\n.call span {\n  color: #4580ad;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `EoZJ7cmvuyG9sJ6kMLFt`,
	"label": `eDApc_KxdmRV8Hnbk0FR`,
	"email": `phC4uJq5FDhSIwIA7TAu`,
	"formButtons": `mEBzzTgG72BlCOC3BoCG`,
	"file": `liLd6CGon7UCzzUmqHA_`,
	"submit": `rACCteIfS_NPdkGNt94Y`,
	"small": `pAUA5hzv5woqDFAo9vHM`,
	"success": `ivRByHZLIrQXSRwe5sVd`,
	"help": `ADZVLktnXCVjR3R322f7`,
	"icon": `Wym7mvh7OClMmEAAf49d`,
	"call": `IxwNDCQQJNUbzeUbLgem`
};
export default ___CSS_LOADER_EXPORT___;
